<template>
  <b-container class="home">
    <b-row>
      <b-col order="2" order-sm="2" order-md="1">
        <h1>{{ $t('global.hello') | capitalize }}.</h1>
        <hr/>
        <div v-html="welcomeMessage"></div>
        <br>
        <CustomButton
          :text="$options.filters.capitalize($t('global.contactMe')) + '!'"
          to="/contact"
        />
      </b-col>
      <b-col order="1" order-sm="1" order-md="2" class="home-right-col">
        <div id="animated-avatar">
          <img src="@/assets/avatar.png" alt="avatar"/>
        </div>
        <blockquote><em>"{{ displayedQuote.text }}"</em></blockquote>
        <p><b>— {{ displayedQuote.author }}</b></p><!-- eslint-disable-line vue-i18n/no-raw-text -->
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import random from 'lodash.random'
export default {
  name: 'Home',
  title: (context) => {
    return context.$t('global.titleSuffix')
  },
  data () {
    return {
      translations: {
        welcome: {
          en: `<p>My name is Hervé Perchec.</p>
              <p>I am an IT engineer, a maker and a handyman. My hobbies are robotics, nature, sciences,
                japanese culture (and horticulture) or music (of course! 😉).
              </p>
              <p>I also love coffee, TED talks and psychoanalysis. 💡</p>
              <p>I spend my time working on my personal projects. So, I am fulfilled 😃.</p>
              <p>And... Yes! I am a fervent defender of free and open-source software. For me, knowledge must be shared. That's all.</p>
              <p>
                You can discover the projects I did at <a href="http://bakoproductions.fr">www.bakoproductions.fr</a>, and,
                more specially, my favourite: <a href="http://mystation.fr">www.mystation.fr</a>.
              </p>`,
          fr: `<p>Je m'appelle Hervé Perchec.</p>
              <p>Ingénieur informaticien, maker, bricoleur, je suis passionné par la robotique, la nature, les sciences,
                la culture (et horticulture) japonaise ou encore la musique (surtout! 😉).
              </p>
              <p>J'aime aussi le café, les conférences TED et la psychanalyse. 💡</p>
              <p>Je consacre la majeure partie de mon temps libre à mes projets personnels, dans lesquels je trouve plein épanouissement 😃.</p>
              <p>Et... Ah oui! Je suis fervent défenseur du logiciel libre et de l'Open-Source. Pour moi, la connaissance se partage. C'est tout.</p>
              <p>
                Vous pouvez aller découvrir les projets que j'ai réalisés sur <a href="http://bakoproductions.fr">www.bakoproductions.fr</a>, et,
                plus particulièrement, celui qui me tient le plus à coeur: <a href="http://mystation.fr">www.mystation.fr</a>.
              </p>`
        }
      },
      quotes: [
        {
          text: 'Patience you must have my young padawan.',
          author: 'Yoda'
        },
        {
          text: 'Do or do not. There is no try.',
          author: 'Yoda'
        },
        {
          text: 'Happiness is when what you think, what you say, and what you do are in harmony.',
          author: 'Gandhi'
        },
        {
          text: 'Where there is love there is life.',
          author: 'Gandhi'
        },
        {
          text: 'Look deep into nature, and then you will understand everything better.',
          author: 'Albert Einstein'
        },
        {
          text: 'Try not to become a man of success, but rather try to become a man of value.',
          author: 'Albert Einstein'
        },
        {
          text: 'The true sign of intelligence is not knowledge but imagination.',
          author: 'Albert Einstein'
        },
        {
          text: 'We cannot solve our problems with the same thinking we used when we created them.',
          author: 'Albert Einstein'
        },
        {
          text: 'Only two things are infinite, the universe and human stupidity, and I\'m not sure about the former.',
          author: 'Albert Einstein'
        },
        {
          text: 'Education is what remains after one has forgotten what one has learned in school.',
          author: 'Albert Einstein'
        },
        {
          text: 'In order to carry a positive action we must develop here a positive vision.',
          author: 'Dalai Lama'
        },
        {
          text: 'It always seems impossible until it\'s done.',
          author: 'Nelson Mandela'
        },
        {
          text: 'Even an outcast could overcome an elite if he works hard enough for it.',
          author: 'Son Goku'
        }
      ],
      currentQuote: null
    }
  },
  computed: {
    welcomeMessage: function () {
      return this.translations.welcome[this.$i18n.locale]
    },
    displayedQuote: function () {
      if (this.currentQuote) {
        return this.currentQuote
      } else {
        return {
          text: '',
          author: ''
        }
      }
    }
  },
  mounted () {
    this.currentQuote = this.quotes[random(0, (this.quotes.length - 1))]
  }
}
</script>
