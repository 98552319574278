import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
/* Global registered components */
/** Icons **/
import IconBase from '@/components/elements/icons/IconBase'
import FacebookIcon from '@/components/elements/icons/Facebook'
import TwitterIcon from '@/components/elements/icons/Twitter'
import LinkedInIcon from '@/components/elements/icons/LinkedIn'
import GithubIcon from '@/components/elements/icons/Github'
import Cults3DIcon from '@/components/elements/icons/Cults3D'
import ThingiverseIcon from '@/components/elements/icons/Thingiverse'
/** Buttons **/
import CustomButton from '@/components/elements/buttons/CustomButton'
/* Services */
import detectMobile from '@/services/DetectMobile'
/* Plugins */
// Internationalization
import i18n from '@/i18n'
// Bootstrap-vue
import BootstrapVue from 'bootstrap-vue'
// vue-page-title
import VuePageTitle from 'vue-page-title'

/* Packages */
import capitalize from 'lodash.capitalize'
import uppercase from 'lodash.uppercase'
import SmoothScroll from 'smooth-scroll'

// Declare filters
Vue.filter('capitalize', capitalize)
Vue.filter('uppercase', uppercase)
// Use plugins
Vue.use(BootstrapVue)
Vue.use(VuePageTitle)
// Register global components
Vue.component('IconBase', IconBase)
Vue.component('FacebookIcon', FacebookIcon)
Vue.component('TwitterIcon', TwitterIcon)
Vue.component('LinkedInIcon', LinkedInIcon)
Vue.component('GithubIcon', GithubIcon)
Vue.component('Cults3DIcon', Cults3DIcon)
Vue.component('ThingiverseIcon', ThingiverseIcon)
Vue.component('CustomButton', CustomButton)

// Scroll to top method
Vue.prototype.$scrollTo = (value) => {
  const scroll = new SmoothScroll()
  scroll.animateScroll(value)
}

// Detect mobile method
Vue.prototype.$isMobile = detectMobile

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
