<template>
  <button
    class="custom-button"
    :data-text="text"
    v-html="spannedText"
    @click="handleClick"
  ></button>
</template>

<script>
export default {
  name: 'CustomButton',
  props: {
    text: {
      type: String,
      default: 'button'
    },
    to: String,
    click: {
      type: Function,
      default: null
    }
  },
  computed: {
    spannedText: function () {
      let tmp = ''
      ;[...this.text].forEach(letter => {
        letter = letter === ' ' ? '&nbsp;' : letter // Replace white spaces
        tmp += '<span>' + letter + '</span>'
      })
      return tmp
    }
  },
  methods: {
    routeTo: function (route) {
      this.$router.push(route)
    },
    handleClick: function () {
      if (this.to) {
        this.routeTo(this.to)
      } else {
        this.click()
      }
    }
  }
}
</script>

<style lang="scss">

$bgcolor: $secondary-color;
$hoverBgColor: $primary-color;
$textColor: #fff;
$font: $secondary-font;

.custom-button {
  float: left;
  min-width: 150px;
  max-width: 250px;
  background: $bgcolor;
  color: $textColor;
  display: block;
  padding: 0 2em;
  border: none;
  border-radius: 2px;
  text-shadow: 0px 0px 3px rgba(44, 62, 80, 0.2);
  font-size: 1.2rem;
  font-family: $font;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: theme-color("primary");
    span {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    &::before {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }
  }
  &::before {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1em 0;
    -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
    transition: transform 0.3s, opacity 0.3s;
    -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
    transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  }
  span {
    display: inline-block;
    padding: 1em 0;
    opacity: 0;
    color: #fff;
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
    -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
    transition: transform 0.3s, opacity 0.3s;
    -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
    transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  }
}
/* Support 40 characters */
@for $i from 1 through 40 {
  .custom-button:hover > span:nth-child(#{$i}) {
    -webkit-transition-delay: ($i * 0.020s);
    transition-delay: ($i * 0.020s);
  }
}

</style>
