import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
/* Services */
import detectMobile from '@/services/DetectMobile'
/* Packages */
import SmoothScroll from 'smooth-scroll'

const smoothScroll = new SmoothScroll()

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach((to, from) => {
  // Prevent from bad behavior on mobile
  if (detectMobile()) {
    window.scrollTo(0, 1)
  } else {
    smoothScroll.animateScroll(0, null, { speed: 2000 })
  }
})

export default router
