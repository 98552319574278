<template>
  <svg xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
    :aria-labelledby="iconName"
    role="presentation"
    @mouseenter="handleOver"
    @mouseleave="handleLeave"
    ref="svgContainer"
  >
    <title
      :id="iconName"
      lang="en"
    >{{ iconName }}</title>
    <g ref="toFill" :fill="defaultColor">
      <slot />
    </g>
  </svg>
</template>

<script>
import { SVG } from '@svgdotjs/svg.js'

const DEFAULT_THEME_COLOR = '#FFAB73'

export default {
  name: 'IconBase',
  data () {
    return {
      defaultColor: DEFAULT_THEME_COLOR,
      svgContainer: {},
      toAnimate: {}
    }
  },
  props: {
    iconName: {
      type: String,
      default: 'box'
    },
    viewBox: {
      type: String,
      default: '0 0 24 24'
    },
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: [Number, String],
      default: 24
    },
    hoverColor: {
      type: String,
      default: DEFAULT_THEME_COLOR
    }
  },
  methods: {
    handleOver: function (e) {
      this.toAnimate.animate({
        duration: 400
      }).attr({ fill: this.hoverColor })
    },
    handleLeave: function (e) {
      this.toAnimate.animate({
        duration: 400
      }).attr({ fill: DEFAULT_THEME_COLOR })
    }
  },
  mounted () {
    this.svgContainer = SVG(this.$refs.svgContainer)
    this.toAnimate = SVG(this.$refs.toFill) // Target element to fill
  }
}
</script>
