<template>
  <b-container>
    <b-navbar toggleable="lg">

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-navbar-brand to="/">
        <img src="@/assets/avatar.png" alt="avatar">
      </b-navbar-brand>

      <b-collapse id="nav-collapse" is-nav>

        <b-navbar-nav>
          <b-nav-item to="/">{{ $t('global.pages.home') | capitalize }}</b-nav-item>
          <b-nav-item to="/contact">{{ $t('global.pages.contact') | capitalize }}</b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">

          <b-nav-item-dropdown :text="dropdownTitle" right>
            <b-dropdown-item @click="switchLocale('en')">English</b-dropdown-item>
            <b-dropdown-item @click="switchLocale('fr')">Français</b-dropdown-item>
          </b-nav-item-dropdown>

        </b-navbar-nav>

      </b-collapse>

    </b-navbar>
  </b-container>
</template>

<script>
export default {
  name: 'Navbar',
  computed: {
    dropdownTitle: function () {
      return this.$options.filters.capitalize(this.$t('global.lang')) + ' (' + this.$options.filters.uppercase(this.$i18n.locale) + ')'
    }
  },
  methods: {
    /**
     * Switch to another language
     * @param {string} locale The locale to switch to
     * @return {void}
     */
    switchLocale: function (locale) {
      this.$i18n.locale = locale
    }
  }
}
</script>
