<template>
  <b-container class="404">
    <b-row>
      <b-col>
        <h1>404</h1><!-- eslint-disable-line -->
        <p>{{ $t('global.notFound') }}</p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'ErrorPage404'
}
</script>
