<template>
  <div id="app">
    <!-- Navbar -->
    <Navbar/>
    <!-- Main container -->
    <div id="main-container">
      <transition
        mode="out-in"
        appear
        enter-active-class="animated fadeInRightBig faster"
        leave-active-class="animated fadeOutLeftBig faster"
      >
        <!-- Here the views -->
        <router-view/>
      </transition>
    </div>
    <!-- Footer -->
    <Footer/>
  </div>
</template>

<script>
import Navbar from '@/components/partials/Navbar'
import Footer from '@/components/partials/Footer'
export default {
  components: {
    Navbar,
    Footer
  }
}
</script>

<style lang="scss">
  @import "~#styles/main";
</style>
