import Home from '../views/Home.vue'
import ErrorPage404 from '../views/404.vue'

export const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    alias: [
      '/index.html' // Redirect /index.html to /
    ]
  },
  {
    path: '/contact',
    name: 'Contact',
    // route level code-splitting
    // this generates a separate chunk (contact.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  },
  {
    path: '*',
    component: ErrorPage404
  }
]

export default routes
