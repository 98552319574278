<template>
  <icon-base
    icon-name="Thingiverse"
    :width="width"
    :height="height"
    viewBox="0 0 50 50"
    hover-color="#3091FA"
  >
      <path
        d="M25 2C12.31 2 2 12.31 2 25s10.31 23 23 23 23-10.31 23-23S37.69 2 25 2zm0 2c11.61 0 21 9.39 21 21s-9.39 21-21 21S4 36.61 4 25 13.39 4 25 4zm-9 11v6h6v17h6V21h6v-6H16z" font-weight="400" font-family="sans-serif" white-space="normal" overflow="visible"
      />
  </icon-base>
</template>

<script>
export default {
  name: 'ThingiverseIcon',
  props: {
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: [Number, String],
      default: 24
    }
  }
}
</script>
